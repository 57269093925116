import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { languageOptions, translationsList } from 'lang';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Formik } from 'formik';
import classNames from 'classnames';

import './scss/students-registration.scss';
import { Layout } from 'layout';
import LogoSourcery from 'icons/sourcery-logo.svg';
import LogoDevbridgeCognizantUkraine from 'icons/devbridge-logo-cognizant-ukraine.svg';
import IconSuccess from 'icons/success.svg';
import IconRotate from 'icons/rotate.svg';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { isWhiteSpace, createNewAcademiesArray } from 'utilities';
import { SOURCERY_ACADEMY } from 'shared/mailchimp-endpoints';
import heroBgImage from 'images/testbg.jpg';
import { LoadingIndicator } from 'components/loadingIndicator';
import { RegistrationForm } from './components';

const validationSchema = (translations) => {
  return Yup.object().shape({
    fname: Yup.string()
      .min('1', translations.minFieldText)
      .max('150', translations.maxFieldText)
      .required(translations.requiredFieldText)
      .test(
        'check-whitespace',
        translations.whiteSpaceFieldText,
        (value) => !isWhiteSpace(value)
      ),
    lname: Yup.string()
      .min('1', translations.minFieldText)
      .max('150', translations.maxFieldText)
      .required(translations.requiredFieldText)
      .test(
        'check-whitespace',
        translations.whiteSpaceFieldText,
        (value) => !isWhiteSpace(value)
      ),
    email: Yup.string()
      .email(translations.validEmailFieldText)
      .required(translations.requiredFieldText),
    city: Yup.string()
      .min('1', translations.minFieldText)
      .max('150', translations.maxFieldText)
      .test(
        'check-whitespace',
        translations.whiteSpaceFieldText,
        (value) => !isWhiteSpace(value)
      ),
    academies: Yup.array()
      .required(translations.atLeastOneRequired)
      .min('1', translations.atLeastOneRequired),
  });
};

const StudentsRegistrationPage = () => {
  const SUBMITTED_STATUS = 'submitted';
  const [formIsSubmitted, setIsFormSubmitted] = useState(false);
  const [formBeingSubmitted, setFormBeingSubmitted] = useState(false);
  const [hasSubmissionError, setSubmissionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const languageContext = useContext(GlobalStateContext);
  const english = languageOptions.find((lang) => lang.id === 'en');

  languageContext.setLanguage(english);
  const translation = languageContext.dictionary.translations;

  const validationTranslations = {
    requiredFieldText: translation['form.validation.isRequired'],
    minFieldText: translation['form.validation.minFieldText'],
    maxFieldText: translation['form.validation.maxFieldText'],
    whiteSpaceFieldText: translation['form.validation.noWhitespace'],
    validEmailFieldText: translation['form.validation.validEmail'],
    atLeastOneRequired: translation['form.validation.isRequiredOneAcademy'],
  };

  // This text is visible on initial load and should be in english only (like remaining text in the form)
  // to fix language switch "flash" on page load
  const englishTranslationsForAcademies = translationsList.en.translations;
  const academies = createNewAcademiesArray(
    ['developers', 'front-end', 'testers'],
    englishTranslationsForAcademies
  );

  const handleSubmit = async (values, actions) => {
    setFormBeingSubmitted(true);

    try {
      const academiesToSend = {};

      values.academies.forEach((id) => {
        const selectedAcademy = academies.find((academy) => id === academy.id);
        academiesToSend[selectedAcademy.name] = selectedAcademy.value;
      });

      const result = await addToMailchimp(
        values.email,
        {
          NAME: values.fname,
          LNAME: values.lname,
          CITY: values.city,
          ...academiesToSend,
        },
        SOURCERY_ACADEMY || null
      );

      if (result.result !== 'error') {
        actions.setSubmitting(false);
        actions.setStatus(SUBMITTED_STATUS);
        setIsFormSubmitted(true);
        setFormBeingSubmitted(false);
      } else {
        actions.setSubmitting(false);
        setSubmissionError(true);
        setErrorMessage(result.msg);
        setIsFormSubmitted(false);
        setFormBeingSubmitted(false);
      }
    } catch (e) {
      actions.setSubmitting(false);
      setSubmissionError(true);
      setFormBeingSubmitted(false);
    }
  };

  const handleFieldFocus = () => {
    if (hasSubmissionError) {
      setSubmissionError(false);
    }
  };

  const resetForm = () => {
    setIsFormSubmitted(false);
    setFormBeingSubmitted(false);
    setSubmissionError(false);
  };

  return (
    <Layout
      darkTheme
      className="students-register"
      hasHeader={false}
      hasFooter={false}
    >
      <Helmet>
        <title>Students Registration</title>
      </Helmet>
      <div
        className="students-register__bg"
        style={{ backgroundImage: `url(${heroBgImage})` }}
      />
      <div className="students-register__frame">
        <header className="students-register__header inverted">
          <div className="students-register__logo">
            <LogoSourcery />
          </div>
          <div className="students-register__logo">
            <LogoDevbridgeCognizantUkraine />
          </div>
        </header>
        <div
          className={classNames('students-register__box inverted', {
            'students-register__box--centered': formIsSubmitted,
          })}
        >
          {!formIsSubmitted ? (
            <div>
              <h1 className="students-register__title">
                <span className="students-register__title-small">
                  Register for Next
                </span>
                Sourcery Academy
              </h1>
              <Formik
                initialValues={{
                  fname: '',
                  lname: '',
                  city: '',
                  email: '',
                  academies:
                    academies &&
                    (academies.length !== 1 ? [] : [academies[0].id]),
                }}
                validationSchema={validationSchema(validationTranslations)}
                onSubmit={handleSubmit}
              >
                {() => (
                  <RegistrationForm
                    academies={academies}
                    handleFieldFocus={handleFieldFocus}
                    hasSubmissionError={hasSubmissionError}
                    errorMessage={errorMessage}
                    formBeingSubmitted={formBeingSubmitted}
                  />
                )}
              </Formik>
            </div>
          ) : (
            <div className="students-register__message">
              <IconSuccess className="students-register__message-icon" />
              <h2 className="students-register__message-title">Thank You!</h2>
              <p className="students-register__message-desc">
                Registration completed.
              </p>
              <button
                type="button"
                className="students-register__message-btn btn"
                onClick={() => resetForm()}
              >
                <i className="students-register__message-label">Reset Form</i>
                <IconRotate className="students-register__message-reset" />
              </button>
            </div>
          )}
          {formBeingSubmitted && <LoadingIndicator />}
        </div>
      </div>
    </Layout>
  );
};

export default StudentsRegistrationPage;
