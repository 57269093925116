import React from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormField, FormCheckboxGroup, SubmitButton } from 'components/forms';

export const RegistrationForm = ({
  academies,
  handleFieldFocus,
  hasSubmissionError,
  errorMessage,
  formBeingSubmitted,
}) => {
  return (
    <Form
      name="registration-form"
      method="post"
      className={classNames('students-register__form', {
        submitted: formBeingSubmitted,
      })}
    >
      {hasSubmissionError && (
        <div className="students-register__error">
          {errorMessage ? (
            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
          ) : (
            <div>Error submitting form</div>
          )}
        </div>
      )}
      <FormField
        autoComplete="off"
        label="First name"
        name="fname"
        onFocus={handleFieldFocus}
      />
      <FormField
        autoComplete="off"
        label="Last name"
        name="lname"
        onFocus={handleFieldFocus}
      />
      <FormField
        autoComplete="off"
        label="Your email"
        name="email"
        onFocus={handleFieldFocus}
      />
      <FormField
        autoComplete="off"
        label="City (optional)"
        name="city"
        onFocus={handleFieldFocus}
      />
      {academies && academies.length > 0 && (
        <FormCheckboxGroup
          selectionTitle="Interested in:"
          groupName="academies"
          handleFieldFocus={handleFieldFocus}
          options={academies}
        />
      )}
      <SubmitButton className="students-register__btn btn form-button">
        Register
      </SubmitButton>
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasSubmissionError: PropTypes.bool.isRequired,
  formBeingSubmitted: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  handleFieldFocus: PropTypes.func.isRequired,
  academies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};
